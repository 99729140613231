import request from './request.js'
// import haiping from './haiping.js'
// import { domains } from './config'

export default {
    // 获取签名
    getJsSignature(params) {
        return request({
            url: '/dy/get_js_signature',
            method: 'get',
            params
        })
    },

    // 登录获取 token
    signIn(params) {
        return request({
            url: '/dy/sign_in',
            method: 'post',
            params
        })
    },

    // 获取手机号
    getMobile(params) {
        return request({
            url: '/dy/get_h5_mobile',
            method: 'get',
            params
        })
    }
}
