import { checkVersion, getDyAppVersion, randomString, getUrlQueries } from './utils.js'
import api from '@/api/index.js'
import { Dialog } from 'vant'

const queries = getUrlQueries()
const _env = queries._env || sessionStorage.getItem('_env') || '';
const isDebugger = _env === 'debugger';
const locationHref = location.href;
const timestamp = String(parseInt(Date.now() / 1000)); // 生成签名用的时间戳
const nonce_str = randomString(); // 生成签名用的随机字符串


class AuthSdk {
    constructor() {
        this.sdk = window.douyin_open;
        this.ready = false;
        this.clientKey = '';
        this.urlToken = queries.token || sessionStorage.getItem('token') || '';
        this.agentId = queries.agent_id || sessionStorage.getItem('agent-id');
        sessionStorage.setItem('token', this.urlToken);
        sessionStorage.setItem('agent-id', this.agentId);
        console.log('auth-sdk-init-url-token', this.urlToken);

        if (!this.urlToken) {
            this.init();
        }
    }

    static getInstance() {
        if (!AuthSdk.instance) {
            AuthSdk.instance = new AuthSdk();
        }
        return AuthSdk.instance;
    }

    init() {
        console.log('authsdk---init')
        const checkRes = this.checkVersion();
        if (!checkRes) return false;

        this.checkConfig().then(() => {
            console.log("window.douyin_open", window.douyin_open);
            this.sdk.ready(() => {
                this.ready = true;
                // Config Ready回调
                console.log('sdk ready')
            });

            this.sdk.error(err => {
                // Config error回调
                Dialog.alert({
                    title: 'sdk init error',
                    message: err.error
                })
                console.log('sdk error', err)
            });
        })
    }

    // openConfig 验签上线抖音版本为10.4.0， 如果在调用jumpOpenAuth方法之前验签的话，请先判断抖音版本号
    // 22.2.0 版本以上才支持图文发布
    checkVersion() {
        const { version } = getDyAppVersion();
        if (!version) return false;

        if (checkVersion(version, '10.8.0') < 0) {
            Dialog.alert({
                message: '当前抖音app版本过低，请升级后再打开'
            })
            return false;
        }

        return true;
    }

    // 验证签名
    async checkConfig() {
        console.log('authsdk--checkConfig')
        const { signature, client_key} = await this.getSignature();

        this.clientKey = client_key;

        this.sdk.config({
            params: {
                client_key,
                signature,
                timestamp,
                nonce_str,
                url: locationHref, // 为应用申请的 JSB 安全域名下的链接，需要携带协议。e.g.  https://jsb.security.domain/page.html
            }
        });
    }

    // 获取签名
    getSignature() {
        console.log('authsdk--getSignature')
        return api.getJsSignature({
            nonce_str,
            timestamp,
            url: locationHref
        }).then(res => {
            console.log('getSignature-res',res)
            const pageHead = res.payload.uri_head
            console.log('页面标题', pageHead)
            document.title = pageHead
            return {
                signature: res.payload.signature,
                client_key: res.payload.client_key,
            }
        })
    }

    // 授权登录，拿到 ticket
    authLogin() {
        return this.openAuth().then(ticket => {
            console.log('登录ticket---', ticket)
            return this.signIn(ticket)
        }).then(res => {
            if (res.code === 200) {
                console.log('授权登录成功', res.payload.token)
                return res.payload.token
            }
            return Promise.reject(res)
        }).catch(err => {
            console.log('授权登录失败', err)
            isDebugger && Dialog.alert({
                title: 'authLogin error',
                message: typeof err === 'string' ? err : JSON.stringify(err)
            })
            return Promise.reject(err)
        })
    }

    // 跳转到授权页(sdk>1.0.9就不支持全屏授权了，只支持半屏授权)
    openAuth() {
        return new Promise((resolve, reject) => {
            console.log('跳转到授权页(sdk>1.0.9就不支持全屏授权了，只支持半屏授权)');
            this.sdk.jumpOpenAuth({
                params: {
                    client_key: this.clientKey,
                    // redirect_uri: locationHref, // 安卓会报非法重定向链接，所以去掉
                    state: '',
                    // scope: 'trial.whitelist,user_info,video.data,item.comment',
                    scope: 'user_info,item.comment,data.external.item',
                    response_type: 'code'
                },
                success: (res) => {
                    console.log('ticket', res)
                    if (res.ticket == -2) {
                        reject({
                            code: -2,
                            msg: '用户取消'
                        })
                    } else {
                        resolve(res.ticket)
                    }
                },
                error: err => {
                    console.log('err', err)
                    reject(err)
                }
            });
        })
    }

    // code 换 token
    signIn(code) {
        return api.signIn({
            code
        }).then(res => {
            console.log('登录成功', res)
            return res
        }).catch(err => {
            console.log('登录失败', err)
            isDebugger && Dialog.alert({
                title: 'signIn error',
                message: typeof err === 'string' ? err : JSON.stringify(err)
            })
            return Promise.reject(err)
        })
    }

    // 视频授权
    videoAuth() {
        const dyVideoAuth = () => {
            return new Promise((resolve, reject) => {
                this.sdk.showOpenAuth({   
                    params: {
                        client_key: this.clientKey,
                        state: '',
                        scopes: {
                            // 'video.data': 0,
                            // 'video.list': 0, // 权限失效了
                            'item.comment': 0
                        },
                        response_type: 'code'
                    },
                    success: (res) => {
                        console.log('视频授权-videoAuth成功', res)
                        if (res.ticket == -2) {
                            reject({
                                code: -2,
                                msg: '用户取消'
                            })
                        } else {
                            resolve(res.ticket)
                        }
                        
                        // ticket： Oauth凭据，详见下方说明
                        // grant_permissions： String类型，用户授权的所有scope，以“,”拼接，例：user_info,mobile
                    },
                    error: err => {
                        console.log('视频授权-videoAuth失败', err)
                        reject(err)
                    }
                });
            })
        }

        return dyVideoAuth().then(ticket => {
            console.log('登录ticket---', ticket)
            return this.signIn(ticket)
        }).then(res => {
            if (res.code === 200) {
                console.log('授权登录成功', res.payload.token)
                return res.payload.token
            }
        }).catch(err => {
            console.log('授权登录失败', err)
            isDebugger && Dialog.alert({
                title: 'videoAuth error',
                message: typeof err === 'string' ? err : JSON.stringify(err)
            })
            return Promise.reject(err)
        })
    }

    // 获取手机号授权
    mobileAuth() {
        const dyMobileAuth = () => {
            return new Promise((resolve, reject) => {
                this.sdk.showOpenAuth({   
                    params: {
                        client_key: this.clientKey,
                        state: '',
                        scopes: {
                            mobile_alert: 0
                        },
                        response_type: 'code'
                    },
                    success: (res) => {
                        console.log('手机号授权-mobileAuth-成功', res)
                        if (res.ticket == -2) {
                            reject({
                                code: -2,
                                msg: '用户取消'
                            })
                        } else {
                            resolve(res.ticket)
                        }
                        // ticket： Oauth凭据，详见下方说明
                        // grant_permissions： String类型，用户授权的所有scope，以“,”拼接，例：user_info,mobile
                    },
                    error: err => {
                        console.log('手机号授权-mobileAuth-失败', err)
                        reject(err)
                    }
                });
            })
        }

        return dyMobileAuth().then(ticket => {
            console.log('获取手机号ticket---', ticket)
            return api.getMobile({
                code: ticket
            })
        }).then(res => {
            if (res.code === 200) {
                console.log('获取手机号成功', res.payload.mobile)
                return res.payload.mobile
            }
        }).catch(err => {
            console.log('获取手机号失败', err)
            isDebugger && Dialog.alert({
                title: 'mobileAuth error',
                message: typeof err === 'string' ? err : JSON.stringify(err)
            })
            return Promise.reject(err)
        })
        
    }
}

AuthSdk.install = function(app, options) {
    console.log('authsdk---install')
    // if (!urlToken) {
    //     app.config.globalProperties.$authSdk = {
    //         mode: 'dev', // 开发模式
    //         urlToken,
    //         ready: true
    //     }
    // } else {
        app.config.globalProperties.$authSdk = AuthSdk.getInstance();
    // }
}

export default AuthSdk;
