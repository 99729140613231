import request from "./request.js";

export default {
  // 嗨屏引擎任务
  getHpyqTask(params) {
    return request({
      url: `/dy/hpyq_task`,
      method: "GET",
      params,
    });
  },



  // 嗨屏引擎获取视频
  getHpyqTaskVideo(params) {
    return request({
      url: `/dy/hpyq_task_video`,
      method: "GET",
      params,
      isLoading: true,
    });
  },

  // 获取分享 schema 的参数
  getShareSignature() {
    return request({
      url: `/dy/get_open_signature`,
      method: "get",
    });
  },

  // 获取视频 share_id
  getVideoShareId(params) {
    return request({
      url: `/dy/get_share_id`,
      method: "get",
      params,
    });
  },

  // 上传视频
  uploadVideo(data) {
    return request({
      url: `/dy/upload_video`,
      method: "post",
      data,
    });
  },

  // 获取重新合成的视频
  getUpdateGenerateVd(params) {
    return request({
      url: `/dy/get_resynthesis_video`,
      method: "get",
      params,
      preventError: true,
    });
  },

  // 提交替换素材
  commitGenerate(data) {
    return request({
      url: `/dy/resynthesis`,
      method: "post",
      data,
    });
  },

  // 获取阿里云上传token
  getUploadAliToken(data) {
    return request({
      url: `/dy/getOssToken`,
      method: "get",
      data,
    });
  },

  // 获取阿里云资源id
  getAliMaterialId(data) {
    return request({
      url: `/dy/registerMediaInfo`,
      method: "post",
      data,
    });
  },

    // 播放排行榜
    getRankingList(params) {
      return request({
        url: '/dy/rankings_list',
        method: 'get',
        params
      })
    },

    // 打榜采集字段详情
    getGatherInfo(params) {
      return request({
        url: '/dy/gather_info',
        method: 'get',
        params
      })
    },

     // 保存采集数据
    saveGatherData(data) {
      return request({
        url: `/dy/save_gather_data`,
        method: "post",
        data,
      });
    },
    // 任务图片
    getTaskImg(params) {
      return request({
        url: `/dy/get_active_img`,
        method: "get",
        params,
      });
    },
    // 获取图片分享id
    getImgShareId(params) {
      return request({
        url: `/dy/get_img_share_id`,
        method: "get",
        params,
      });
    },
};


