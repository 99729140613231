import axios from 'axios'

const retryOldRequest = {
    // 维护失败的请求
    requestQuery: [],

    listen(response) {
        return new Promise((resolve, reject) => {
            console.log('retryOldRequest--listen', this.requestQuery)
            this.requestQuery.push(newToken => {
                let config = response.config || {};
                console.log('重新请求接口', config)
                config.headers.token = newToken;
                // resolve(axios(config))
                return axios(config).then(res => {
                    console.log('重新请求接口成功', res)
                    resolve(res)
                }).catch(err => {
                    console.log('重新请求接口失败', err)
                    resolve(err)
                })
            })
        })
    },

    trigger(newToken) {
        console.log('retryOldRequest--trigger', newToken, this.requestQuery.length)
        const reqList = []
        this.requestQuery.forEach(fn => {
            console.log('retryOldRequest--trigger--foreach', fn)
            reqList.push(fn(newToken))
        })

        Promise.all(reqList).then((res) => {
            console.log('promise-all-req', res)
            this.requestQuery = [];
        }).catch((err) => {
            console.log('promise-all-req-err', err)
            this.requestQuery = [];
        })

    }
}

export default retryOldRequest;