import {
  Button,
  Popup,
  Dialog,
  Toast,
  Icon,
  Empty,
  Uploader,
  Overlay,
  Loading,
  Progress
} from "vant";

export default function useComponents(app) {
  app.use(Empty);
  app.use(Icon);
  app.use(Button);
  app.use(Popup);
  app.use(Toast);
  app.use(Dialog);
  app.use(Uploader);
  app.use(Overlay);
  app.use(Loading);
  app.use(Progress);
}
