<template>
    <div class="page-loading" v-if="show">
        
    </div>
</template>

<script>
export default {
    props: {
        show: Boolean
    }
}
</script>

<style lang="scss" scoped>
.page-loading {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff url(~@/assets/images/loading.gif) center 100px no-repeat;
    background-size: 180px;
}
</style>