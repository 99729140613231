import { getUrlParam } from '@/utils/utils'

const domains = {
    dev: 'https://proapi.zhenhii.com', // 测试
    pre: 'https://proapi.zhenhii.com', // 预发布
    pro: 'https://saasapi.zhenhii.com', // 正式环境
}

console.log('base-url---', process.env.VUE_APP_BASE_URL)

let _env = getUrlParam('_env') || sessionStorage.getItem('_env') || '';

sessionStorage.setItem('_env', _env)

const envType = {
    development: 'dev',
    preview: 'pre',
    production: 'pro'
}

if (!_env) {
    _env = envType[process.env.NODE_ENV]
}

let baseURL = domains[_env];

console.log('当前接口环境', baseURL)


export {
    domains,
    baseURL
};