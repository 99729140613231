import { getUrlQueries } from '@/utils/utils'
import AuthSdk from '@/utils/auth'

export default {
    data() {
        return {
            queries: {}
        }
    },
    created() {
        this.queries = getUrlQueries();
        console.log('mixin--created', this.queries)
        if (!this.$authSdk.urlToken) { // 在 app 内访问
            this.$authSdk.sdk && this.$authSdk.sdk.ready(() => {
                // console.log('在 app 内访问')
                console.log('sdk ready')
                this.checkLogin().then(() => {
                    this.sdkReady && this.sdkReady();
                })
            })
            this.$authSdk.sdk.error(() => {
                this.sdkError && this.sdkError();
            })
        }
    },
    beforeMount() {
        if (this.$authSdk.urlToken) {
            // console.log('不在 app 内访问')
            this.checkLogin().then(() => {
                this.sdkReady && this.sdkReady();
            })
        }
    },
    methods: {
        checkLogin() {
            console.log('检查是否登录', !!this.$authSdk.urlToken)
            if (!this.$authSdk.urlToken) {
                const authSdk = AuthSdk.getInstance();
                return authSdk.authLogin().then(token => {
                    if (token) {
                        console.log('登录结果--token', token)
                        sessionStorage.setItem('token', token)
                        this.$authSdk.urlToken = token
                    } else {
                        sessionStorage.removeItem('token')
                        this.$authSdk.urlToken = ''
                        this.$dialog.alert({
                            message: '登录失败，请刷新当前页面'
                        })
                    }
                })
            } else {
                return Promise.resolve()
            }
        }
    }
}