import useComponents from "./vant-ui";
// import mixinOptions from './mixin'
import AuthSdk from "@/utils/auth";
import PageLoading from "@/components/PageLoading.vue";

// import VConsole from 'vconsole'
// import { getUrlQueries } from '@/utils/utils'
// const { _debugger } = getUrlQueries()
// if (_debugger == 1) {
//     new VConsole()
// }

export default function commonInit(app) {
  useComponents(app);
  app.use(AuthSdk);
  // app.mixin(mixinOptions)
  app.component("page-loading", PageLoading);
}
