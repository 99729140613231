import axios from "axios";
import { baseURL } from "./config";
import { Toast } from "vant";
import AuthSdk from "@/utils/auth";
import { getUrlQueries } from "@/utils/utils";
import retryOldRequest from "./refreshToken";

const queries = getUrlQueries();
const agentId = queries["agent_id"] || sessionStorage.getItem("agent-id");
const urlToken = queries["token"] || sessionStorage.getItem("token") || "";
console.log("url里的token", urlToken);

let isRefreshToken = false; // 是否正在刷新 token

const request = axios.create({
  baseURL,
  timeout: 120 * 1000,
  headers: {
    // 'X-Custom-Header': 'foobar',
  },
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = sessionStorage.getItem("token");

    if (urlToken) {
      config.headers.token = urlToken;
    } else if (token) {
      config.headers.token = token;
    }

    config.headers["agent-id"] = agentId;

    if (config.isLoading) {
      Toast.loading({
        message: config.loadingText || "加载中...",
        forbidClick: true,
      });
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    console.log("req--" + response.data.code + "--" + response.config.url);
    const { data, config } = response;
    const { code } = data;
    if (code === 200) {
      const token = data.payload.token;
      token && sessionStorage.setItem("token", token);

      return data;
    }

    if (isSessionInvalid(code)) {
      return reAuthLogin(response)
        .then((response) => {
          if (response.data.code === 200) {
            console.log("重新登录成功");
            return response.data;
          } else {
            console.log("重新登录失败");
          }
        })
        .catch((response) => {
          console.log("重新登录失败", response.data.msg);
          return Promise.reject(response.data);
        });
    }

    if (!config.preventError) {
      // 是否阻止底层报错弹框
      Toast(data.msg);
    }

    return Promise.reject(data);
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log("请求---error", error);
    if (error.code === "ECONNABORTED") {
      Toast("网络不佳，请检查网络");
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

// 检查session是否失效
function isSessionInvalid(code) {
  // 40110 用户不存在
  // 40100 用户未登录
  // 40132 令牌过期
  // const statusArr = [40132, 40131, 40110, 40100, 40109, 40210];
  const statusArr = [401];
  return statusArr.includes(code);
}

// 重新授权登录
function reAuthLogin(response) {
  const authSdk = AuthSdk.getInstance();
  console.log("request--reAuthLogin--ready", authSdk.ready);
  if (!authSdk.ready) return Promise.reject(response);

  if (!isRefreshToken) {
    isRefreshToken = true;

    authSdk
      .authLogin()
      .then((token) => {
        isRefreshToken = false;

        if (token) {
          console.log("登录结果--token", token, retryOldRequest);
          sessionStorage.setItem("token", token);
          retryOldRequest.trigger(token);
        } else {
          sessionStorage.removeItem("token");
          this.$dialog.alert({
            message: "登录失败，请刷新当前页面",
          });
          return Promise.reject(response);
        }
      })
      .catch((err) => {
        isRefreshToken = false;
        sessionStorage.removeItem("token");
        this.$dialog.alert({
          message: "登录失败，请刷新当前页面",
        });
        return Promise.reject(response);
      });
  }

  return retryOldRequest.listen(response);
}

export default request;
